import { useEffect, useState } from "react";
import bcx_icon from "../../assets/images/bcx-icon.svg";
import Footer from "../../components/Footer";
import menu from "../../assets/images/hamburger.svg";
import menu_black from "../../assets/images/hamburger-black.svg";
import fb from "../../assets/images/facebook-logo.svg";
import ig from "../../assets/images/instagram-logo.svg";
import lin from "../../assets/images/linkedin-logo.svg";
import whapp from "../../assets/images/whatsapp-logo.svg";
import x from "../../assets/images/twitter-x-logo.svg";
import tk from "../../assets/images/tiktok.svg";
import snap from "../../assets/images/snapchat.svg";
import pin from "../../assets/images/map-pin.svg";
import MapContainer from "../../components/MapContainer";
import noPicture from "../../assets/images/NoImage.png";
import { backwardsWebsite, saveContact } from "../../utils/utils";
import
{
  DocumentData,
  DocumentReference,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  or,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth1, db } from "../../services/firebase";
import { Contact, ContactDoc, EliteCompany, Reseller, User } from "../../types";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Language, lang } from "../../languages";
import { useDispatch, useSelector } from "react-redux";
import { LanguageSelector } from "../../components/LanguageSelector";
import { setLanguage } from "../../redux/feature/languageSlice";
import { getCurrentTimeInEST, phoneTypel18n, stringToPhoneType } from "../../utils/features";
import { ShareInfoPopUp } from "../../components/ShareInfoPopUp";
import { ProfileMenu } from "../../components/ProfileMenu";
import { LinkItUrl } from 'react-linkify-it';

export const Profile = () =>
{
  const params = useParams();
  const orderNumber = params.param;
  const navigate = useNavigate();
  const location = useLocation();
  const [menuToggle, setMenuToggle] = useState(true);
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);
  const [userNotFound, setUserNotFound] = useState(false);
  const language: Language = useSelector((state: any) => state.language);
  const [languageSelection, setLanguageSelection] = useState<Language | null>(null);
  const dispatch = useDispatch();
  const [resellerClient, setResellerCLient] = useState<Reseller>();
  const [openTWS, setTWS] = useState(false);
  const [eliteCompanyDoc, setEliteCompanyDoc] = useState<EliteCompany>();
  const getUser = async (
    order: string,
    callbackFunction: Function,
    errorCallback: Function,
    eliteCallback: Function
  ) =>
  {
    if (order === "")
    {
      auth1.onAuthStateChanged(async (user) =>
      {
        let userDoc: DocumentData | undefined = undefined;
        if (user)
        {
          const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
          userDoc = (await getDoc(authUserRef)).data();

          const userTyped = { ...userDoc, website: backwardsWebsite(userDoc!.website) } as User;

          if (userTyped.eliteCompany !== undefined)
          {
            eliteCallback(userTyped.eliteCompany);
            await getReseller(userTyped.eliteCompany)
          } else
          {
            setLoading(false);
            await getReseller(userTyped.orderNumber)
          }

          callbackFunction(userTyped);
        }
      });
    } else
    {

      if (order.length < 4)
      {
        errorCallback();
        return;
      }
      //----------------------------------
      //Fix card numbers like '[123456789]' ['123456789']
      if (/\['.*'\]/.test(order))
      {
        order = order
          .replaceAll("'", '')
          .replace('[', '')
          .replace(']', '')
          .replace('%5B', '')
          .replace('%5D', '');
        window.location.pathname = `/profile/${order}`;
      }
      //----------------------------------
      const q = query(
        collection(db, "users"),
        or(
          where("orderNumber", "==", order),
          where("customUrl", "==", order.toLocaleLowerCase())
        )
      );
      const querySnapshot = await getDocs(q);
      let data = querySnapshot.docs[0]?.data();

      if (data === undefined)
      {
        errorCallback();
      } else
      {
        const user = { ...data, website: backwardsWebsite(data.website) } as User;

        callbackFunction(user);
        if (user.eliteCompany !== undefined)
        {
          eliteCallback(user.eliteCompany);
          await getReseller(user.eliteCompany)
        } else
        {
          setLoading(false);
          await getReseller(user.orderNumber)
        }

        if (order === user.customUrl)
        {
          await addMetric(user, { visits: increment(1) })
        } else if (order === user.orderNumber && !user.customUrl)
        {
          await addMetric(user, { visits: increment(1) })
        }


        if (
          location.pathname.toLocaleLowerCase() ===
          `/profile/${user.orderNumber}` &&
          user.customUrl
        )
        {
          window.location.pathname = user.customUrl
            ? "profile/" + user.customUrl!
            : window.location.pathname;
        }
      }
    }

    /*
    const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
    const userDoc = (await getDoc(authUserRef)).data();
    return userDoc as User;*/
  };

  async function getReseller(value: string | DocumentReference)
  {
    let args = (typeof value === 'string') ?
      { query: 'orderNumber', param: value } :
      { query: 'company', param: value }

    const resellerQ = query(
      collection(db, 'resellerClients'),
      where(args.query, '==', args.param),
    )

    const resellerSnapshot = await getDocs(resellerQ);
    const reseller = resellerSnapshot.docs[0]?.data();

    if (reseller)
    {
      setResellerCLient(reseller as Reseller)
    }
  }

  async function addMetric(user: User, data: any)
  {
    if (orderNumber)
    {
      const q = query(
        collection(db, 'metrics'),
        where('orderNumber', '==', user.orderNumber));
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty)
      {
        // If document exists, update it
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, data)

      } else
      {
        // If document doesn't exist, create it
        await addDoc(collection(db, 'metrics'), {
          orderNumber: user.orderNumber,
          ...data
        })
      }
    }
  }

  const setStateUser = (user: User) =>
  {
    setUser(user);
    if (!user.finishedCreating && !orderNumber)
    {
      navigate("/user-not-set-up");
    }
    if (!user.finishedCreating && orderNumber)
    {
      navigate(`/user-not-found/${orderNumber}`);
    }
    setLanguageSelection(user.language ?? Language.ENG);
  };

  const setStateUserNotFound = () =>
  {
    setUserNotFound(true);
    setLoading(false);
  };

  const setStateElite = async (data: DocumentReference) =>
  {
    const eliteCompanyDocs = (await getDoc(data)).data();
    const eliteCompany = eliteCompanyDocs as EliteCompany;
    setEliteCompanyDoc(eliteCompany);
    setLoading(false);
  };

  async function updateLanguage(lang: Language)
  {
    const userRef = doc(db, "users", auth1.currentUser?.uid!);
    const userDoc = (await getDoc(userRef)).data() as User;
    if (userDoc.orderNumber === user.orderNumber)
    {
      await updateDoc(userRef!, { language: lang });
    }
  }

  async function twoWayShare(contact: Contact)
  {
    const q = query(
      collection(db, "users"),
      where("orderNumber", "==", user.orderNumber)
    );
    const querySnapshot = await getDocs(q);
    let ref = querySnapshot.docs[0]?.ref;
    let data = querySnapshot.docs[0]?.data() as User;
    let contactDoc: ContactDoc = { ...contact, user: ref, ownerOrder: data.orderNumber, saved: false, date: Timestamp.fromDate(new Date(getCurrentTimeInEST())) }
    let location: GeolocationCoordinates | undefined;

    if (navigator.geolocation && navigator.permissions)
    {
      await navigator.permissions.query({ name: 'geolocation' });
      try
      {
        location = await new Promise<GeolocationCoordinates | undefined>((resolve, reject) =>
        {
          navigator.geolocation.getCurrentPosition((position) => resolve(position.coords)
            , (r) => reject(r), {
            timeout: 2000 // ms
          });
        });
      } catch (error)
      {
        console.log(error)
      }

    }
    if (location)
    {
      contactDoc = { ...contactDoc, latitude: location.latitude, longitude: location.longitude }
    }

    if (ref && data)
    {
      addDoc(collection(db, "contacts"), contactDoc)
    }
    setTWS(false);
    await saveContact(user);
  }
  function getJobTitle(title?: string, company?: string)
  {

    if (title && company)
    {
      return `${title} ${lang.at[language]} ${company}`
    }
    else
    {
      if (title && !company)
      {
        return `${title}`
      } else
      {
        return `${company}`
      }
    }
  }

  function getLogoURL()
  {
    //eliteCompanyDoc?.useLogoURL? resellerClient ? resellerClient.url : '/'
    if (eliteCompanyDoc && eliteCompanyDoc.useLogoURL)
    {
      return eliteCompanyDoc.logoURL ?? 'https://businesscardx.com/shop';
    } else
    {
      return 'https://businesscardx.com/shop'
    }
  }

  useEffect(() =>
  {
    window.scrollTo(0, 0);
    const getData = async () =>
    {
      await getUser(
        orderNumber || "",
        setStateUser,
        setStateUserNotFound,
        setStateElite
      );
    };
    getData();
  }, []);

  useEffect(() =>
  {
    if (languageSelection)
    {
      dispatch(setLanguage(languageSelection!));
      updateLanguage(languageSelection!);
    }
  }, [dispatch, languageSelection])


  if (loading)
  {
    return (
      <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
        <div className=" relative">
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }
  if (userNotFound)
  {
    navigate(`/user-not-found/${orderNumber}`);
  }
  return (
    <>
      <div className="relative overflow-hidden bg-ofwhite">

        {openTWS && <ShareInfoPopUp
          isOpen={openTWS}
          language={language}
          onClose={() => { setTWS(false) }}
          onSubmit={async (contact) =>
          {
            await twoWayShare(contact).then(async () =>
            {
              await addMetric(user, {
                contactsSaved: increment(1),
                leadsCreated: increment(1)
              })
            });
          }}
        />}

        <div className="flex lg:flex-row flex-col lg:justify-between w-full">
          <div className="lg:block hidden w-[416px]  text-primary" style={{ color: eliteCompanyDoc?.useCustomColors ? (eliteCompanyDoc.primaryColor) : '' }}>

            <svg stroke="currentColor" viewBox="0 0 416 2493" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="37.5" y="2184.5" width="306" height="228" rx="8.5" transform="rotate(90 37.5 2184.5)" fill='none' strokeWidth="5" />
              <rect x="-163.5" y="458.5" width="219" height="370" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-151.5" y="1040.5" width="219" height="545" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-63.5" y="1751.5" width="219" height="545" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="271.5" y="-278.5" width="142" height="370" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="72.5" y="-130.5" width="307" height="370" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-9.5" y="-75.5" width="142" height="370" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-112.5" y="648.5" width="360" height="149" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-109.5" y="1495.5" width="360" height="149" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-151.5" y="2066.5" width="360" height="149" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-142.5" y="-26.5" width="489" height="149" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-109.5" y="1187.5" width="489" height="628" rx="8.5" fill='none' strokeWidth="5" />
              <rect x="-367.5" y="493.5" width="489" height="628" rx="8.5" fill='none' strokeWidth="5" />
            </svg>



          </div>
          <div className="flex flex-col items-center justify-start mb-[10px] w-full ">

            <div className="relative lg:w-[393px] w-full mb-[20px] mt-[10px] md:px-0 px-[18px]">
              <div
                className="relative flex flex-row items-center justify-center h-[84px] bg-text w-full rounded-xl shadow-custom "
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? "#FFFFFF"
                    : "#161617",
                }}
              >
                <div className="flex-1"></div>
                <Link to={getLogoURL()} target="_blank" rel="noreferrer">
                  <img
                    alt='Company Logo'
                    className="cursor-pointer max-w-[150px] max-h-[50px] w-[150px] h-[50px] object-contain"
                    src={
                      eliteCompanyDoc?.useCustomColors
                        ? eliteCompanyDoc?.logo[0]
                        : bcx_icon
                    }
                  />
                </Link>
                <div className="flex-1">
                  <button
                    className={
                      (orderNumber ? "hidden" : "block") +
                      "  ml-auto self-start  lg:mr-[38px] mr-[29px] "
                    }
                    onClick={() =>
                    {
                      setMenuToggle(!menuToggle);
                    }}
                  >
                    <img
                      alt="menu"
                      src={eliteCompanyDoc?.useCustomColors ? menu_black : menu}
                    />
                  </button>
                  <div className={((!orderNumber) ? "hidden" : "block") + " ml-auto w-[40px] mr-[30px] rounded-md border-white border-[2px]"}
                    style={{
                      borderColor: eliteCompanyDoc?.useCustomColors
                        ? "#000000"
                        : "#ffffff",
                      color: eliteCompanyDoc?.useCustomColors
                        ? "#000000"
                        : "#ffffff",
                    }}>
                    <LanguageSelector valueClass={"text-[16px]/[20px]"} arrow={false} items={[{ text: Language.ENG }, { text: Language.FRE }]} onChange={(a) => { setLanguageSelection(a) }} value={language} />
                  </div>
                </div>
              </div>
            </div>

            <div className=" relative lg:w-[393px] w-full md:px-0 px-[18px]  ">
              <div className="bg-black rounded-xl overflow-hidden shadow-custom">
                <div className="flex flex-col items-center w-full justify-center z-30 relative overflow-clip">
                  <div className=" absolute bottom-0 z-10 w-full h-[173px] bg-gradient-to-b from-transparent via-gray-900 to-black" />
                  <div className="absolute z-50 -bottom-[10px] left-0">
                    <div className=" z-20 flex ml-[25px] mb-[7px] font-custom font-medium text-[36px]/[49px] text-white shadow-text-custom ">
                      {`${user?.name ?? ''} ${user?.lastName ?? ''}`}
                    </div>
                  </div>
                  <img
                    alt="profile_picture"
                    id="profile_picture"
                    className="h-[381px] w-full lg:w-[393px] object-cover z-0"
                    src={
                      user?.photo && user.photo !== "no" ? user.photo : noPicture
                    }
                  />
                </div>
                <div className="z-10 flex flex-col lg:w-[393px] mt-[10px] bg-black rounded-b-lg justify-end">
                  <div className="flex flex-wrap flex-row">
                    <div className=" z-20 flex mx-[25px] mb-[19px] font-custom font-light text-[24px]/[32px] text-white shadow-text-custom bg-transparent">
                      {getJobTitle(user.jobTitle, user.company)}
                    </div>
                  </div>


                  <div className="px-[25px] z-50 bg-black">
                    <button
                      onClick={
                        async () =>
                        {
                          await saveContact(user).then(async () =>
                          {
                            await addMetric(user, { contactsSaved: increment(1) })
                          })
                        }
                      }
                      className="lg:w-[338px] self-center w-full font-custom text-[17px]/[32px] font-medium rounded-[26px] tracking-[0.05em] hover:bg-inset hover:text-white bg-white py-[9px] "
                    >
                      {lang.saveContact[language]}
                    </button>
                  </div>

                  <div className="px-[25px] mt-[12px] z-50 bg-black w-full">
                    <button
                      onClick={() =>
                      {
                        setTWS(true);

                      }}
                      className="lg:w-[338px] self-center w-full font-custom text-[17px]/[32px] font-medium rounded-[26px] tracking-[0.05em] bg-primary text-button hover:bg-secondary hover:text-white py-[9px]"
                      style={{
                        backgroundColor: eliteCompanyDoc?.useCustomColors
                          ? eliteCompanyDoc?.primaryColor
                          : "",
                        color: eliteCompanyDoc?.useCustomColors ? (eliteCompanyDoc.primaryWhite ? '#ffffff' : '#000000')
                          : "",
                      }}
                    >
                      {lang.twoWayShare[language]}
                    </button>
                  </div>

                  <div className=" flex flex-row justify-between items-end lg:w-[393px] w-full pb-[19px] pt-[12px] bg-black rounded-b-[10px]">
                    <div className=" flex flex-row flex-wrap  gap-[6px] items-start mb-[-6px] w-full pl-[25px]">
                      {user.instagram && user.instagram !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start "
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { ig: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { ig: increment(1) }) }}
                          href={
                            "https://instagram.com/" +
                            user?.instagram.replace("@", "")
                          }
                        >
                          <img className="w-[45px] h-[45px]" alt="Instagram logo" src={ig} />
                        </a>
                      ) : (
                        <></>
                      )}

                      {user.facebook && user.facebook !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start"
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { fb: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { fb: increment(1) }) }}
                          href={
                            "https://" + user?.facebook.replace("https://", "")
                          }
                        >
                          <img className="w-[45px] h-[45px]" alt='Facebook logo' src={fb} />
                        </a>
                      ) : (
                        <></>
                      )}

                      {user.whatsapp && user.whatsapp !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start"
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { wp: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { wp: increment(1) }) }}
                          href={user?.whatsapp}
                        >
                          <img className="w-[45px] h-[45px]" alt='Whatsapp logo' src={whapp} />
                        </a>
                      ) : (
                        <></>
                      )}
                      {user.linkedin && user.linkedin !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start"
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { li: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { li: increment(1) }) }}
                          href={
                            "https://" + user?.linkedin.replace("https://", "")
                          }
                        >
                          <img className="w-[45px] h-[45px]" alt='LinkedIn logo' src={lin} />
                        </a>
                      ) : (
                        <></>
                      )}
                      {user.tiktok && user.tiktok !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start"
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { tk: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { tk: increment(1) }) }}
                          href={"https://" + user?.tiktok.replace("https://", "")}
                        >
                          <img className="w-[45px] h-[45px]" alt='TikTok logo' src={tk} />
                        </a>
                      ) : (
                        <></>
                      )}
                      {user.twitter && user.twitter !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start"
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { tw: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { tw: increment(1) }) }}
                          href={
                            "https://" + user?.twitter.replace("https://", "")
                          }
                        >
                          <img className="w-[45px] h-[45px]" alt='Twitter logo' src={x} />
                        </a>
                      ) : (
                        <></>
                      )}
                      {user.snapchat && user.snapchat !== "no" ? (
                        <a
                          className="mb-[6px] flex justify-start"
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { sc: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { sc: increment(1) }) }}
                          href={
                            "https://www.snapchat.com/add/" + user?.snapchat.replace("@", "")
                          }
                        >
                          <img className="w-[45px] h-[45px]" alt='Snapchat logo' src={snap} />
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-[21px] mt-[33px]">
              {user.phone ? (
                user.phone.map((e, i) =>
                {
                  return (
                    <a
                      href={"tel:" + e.number + (e.ext ? "," + e.ext : "")}
                      key={"phone_" + i}
                      onMouseDown={async () => { await addMetric(user, { phoneCalls: increment(1) }) }}
                      onKeyDown={async () => { await addMetric(user, { phoneCalls: increment(1) }) }}
                      className={
                        (e.number.length > 0 ? "block" : "hidden") +
                        " w-[333px] h-[52px] font-custom font-medium shadow-custom text-[17px]/[23px] text-center  hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")
                      }
                      style={{
                        backgroundColor: eliteCompanyDoc?.useCustomColors
                          ? eliteCompanyDoc?.primaryColor
                          : "#fbbc05",
                      }}
                    >
                      <span className={"text-[13px]/[32px] tracking-[0.05em] mr-[8px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}>
                        {
                          phoneTypel18n(stringToPhoneType(e.type), language)
                        }
                      </span>
                      {formatPhoneNumberIntl(e.number)}
                      {e.ext && (
                        <span className={"text-[13px]/[32px] tracking-[0.05em] ml-[8px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}>
                          ext. {e.ext}
                        </span>
                      )}
                    </a>
                  );
                })
              ) : (
                <></>
              )}

              {user.email ? (
                user.email.map((e, i) => (
                  <a
                    href={"mailto:" + e}
                    key={"email_" + i}
                    onMouseDown={async () => { await addMetric(user, { emailTaps: increment(1) }) }}
                    onKeyDown={async () => { await addMetric(user, { emailTaps: increment(1) }) }}
                    className={
                      (e.length > 0 ? "block" : "hidden") +
                      " w-[333px] truncate h-[52px] font-custom font-medium shadow-custom text-[17px]/[23px] text-center hover:bg-secondary bg-primary rounded-[26px] lg:pt-[12px] pt-[12px] lg:pb-[10px] pb-[10px] px-[20px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")
                    }
                    style={{
                      backgroundColor: eliteCompanyDoc?.useCustomColors
                        ? eliteCompanyDoc?.primaryColor
                        : "#fbbc05",
                    }}
                  >
                    {e}
                  </a>
                ))
              ) : (
                <></>
              )}
              {user.website && user.website.length > 0 ? (
                user.website.map((e, i) => (
                  <a
                    href={"https://" + e.website.replace("https://", "")}
                    target="_blank"
                    key={"website_" + i}
                    onMouseDown={async () => { await addMetric(user, { websiteVisits: increment(1) }) }}
                    onKeyDown={async () => { await addMetric(user, { websiteVisits: increment(1) }) }}
                    className={
                      (e.website.length > 0 ? "block" : "hidden") +
                      " w-[333px] truncate h-[52px] font-custom font-medium shadow-custom text-[17px]/[23px] text-center hover:bg-inset bg-button rounded-[26px] lg:pt-[12px] pt-[12px] lg:pb-[10px] pb-[10px] px-[20px] " + ((eliteCompanyDoc?.secondaryWhite || !eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")
                    }
                    rel="noreferrer"
                    style={{
                      backgroundColor: eliteCompanyDoc?.useCustomColors
                        ? eliteCompanyDoc?.secondaryColor
                        : "#252529",
                    }}
                  >
                    {e.title.length > 0 ? e.title : e.website.replace("https://", "")}
                  </a>
                ))
              ) : (
                <></>
              )}
            </div>

            {user.bio ? (
              <div className="mt-[23px] border-[2px] border-button rounded-[11px] py-[33px] px-[29px]">
                <h2 className="font-custom font-medium text-left text-[30px]/[41px] text-button mb-[26px]">
                  {lang.whoAmI[language]}
                </h2>

                <LinkItUrl className="anchor-color">
                  <style>
                    {`.anchor-color{color:${eliteCompanyDoc?.useCustomColors ? eliteCompanyDoc?.primaryColor : '#252529'};}`}
                  </style>
                  <pre className="w-[275px] font-custom font-light text-button text-[14px]/[25px] tracking-[0.05em] whitespace-pre-wrap">{user?.bio}</pre>
                </LinkItUrl>

              </div>
            ) : (
              <></>
            )}

            <div className="self-center w-[393px] mt-[23px]">
              <div className="flex flex-row gap-[12px]  mx-[30px]  ">
                <img
                  alt='Map pin icon'
                  className={
                    (user.city || user.stateProvince || user.country
                      ? "block"
                      : "hidden")
                  }
                  src={pin}
                />

                <div className=" flex flex-row flex-wrap items-center w-full ">
                  <div className={((user.city || user.stateProvince || user.country) ? 'h-fit text-[30px]/[41px]' : 'h-auto text-[0px]/[0px]') + " whitespace-break-spaces font-custom font-medium text-left  text-button overflow-hidden custom-div-location"}>
                    {user.city ? user.city.trim() + "," : ""}{" "}
                    {user.stateProvince ? user.stateProvince.trim() + "," : ""}{" "}
                    {user.country ? user.country.trim() : ""}
                  </div>
                </div>
              </div>

              {user.addresses ? (
                user.addresses.map((e, i) => (
                  <div key={'Address_' + i} className="shadow-custom rounded-[12px] mb-[14px]  ">
                    <div className="flex flex-row justify-between items-center pr-[17px] pl-[30px] py-[17px]">
                      <div className="flex flex-col">
                        <h2 className="font-custom font-medium text-left text-[30px]/[41px] text-button mb-[10px]">
                          {e.name}
                        </h2>
                        <div className="w-[150px] font-custom font-light text-[14px]/[25px] tracking-[0.05em]">
                          <div className="flex flex-row w-full">
                            {e.addressline1} {e.addressline2}
                          </div>

                          <div className="flex flex-row w-full">
                            {e.city}
                            {", "}
                            {e.stateProvince}
                          </div>
                          <div></div>
                          <div>{e.postalCode}</div>
                          <div>{e.country}</div>
                        </div>
                      </div>
                      <div className="overflow-clip relative border-[2px] border-button rounded-[11px] ">
                        <MapContainer
                          onClick={async () => { await addMetric(user, { addressLookups: increment(1) }) }}
                          location={
                            e.addressline1 +
                            " " +
                            e.addressline2 +
                            ", " +
                            e.city +
                            ", " +
                            e.country
                          }
                          classname=""
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}

              {user.media ? (
                user.media.map((e, i) => (
                  <div key={'Media_' + i} className="shadow-custom rounded-[12px]">
                    <div className="flex flex-col px-[30px] py-[25px]">
                      <h2 className="font-custom font-medium text-left text-[30px]/[41px] text-button mb-[28px]">
                        {e.mediaShowcase}
                      </h2>
                      <img
                        alt='Profile meadia showcase thumbnail'
                        className="mb-[18px] object-cover h-[195px] w-[393px] rounded-[7px] overflow-clip"
                        src={e.mediaThumbnail ? e.mediaThumbnail : ""}
                      />
                      <div className="font-custom font-medium text-left text-[25px]/[30px] text-button mb-[14px]">
                        {e.mediaTitle}
                      </div>
                      <div className="w-[333px] font-custom font-light text-[14px]/[25px] tracking-[0.05em] mb-[15px]">
                        {e.mediaMessage}
                      </div>
                      {e.mediaUrl && (
                        <a
                          href={"https://" + e.mediaUrl.replace("https://", "")}
                          target="_blank"
                          rel="noreferrer"
                          onMouseDown={async () => { await addMetric(user, { mediaLookups: increment(1) }) }}
                          onKeyDown={async () => { await addMetric(user, { mediaLookups: increment(1) }) }}
                          className={"w-[333px]  font-custom font-medium shadow-custom text-[17px]/[23px] text-center hover:bg-inset bg-button rounded-[26px] lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.secondaryWhite || !eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                          style={{
                            backgroundColor: eliteCompanyDoc?.useCustomColors
                              ? eliteCompanyDoc?.secondaryColor
                              : "#252529",
                          }}
                        >
                          {e.mediaUrl.replace("https://", "")}
                        </a>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <Link to={resellerClient ? resellerClient.url : '/shop'} target="_blank" rel="noreferrer">
              <button
                className={
                  (orderNumber ? "block" : "hidden") +
                  " w-[333px] font-custom font-medium shadow-custom text-[17px]/[23px] text-center hover:bg-secondary bg-primary rounded-[26px] lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] mb-[23px] mt-[52px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")
                }
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
                onMouseDown={async () => { await addMetric(user, { getACard: increment(1) }) }}
                onKeyDown={async () => { await addMetric(user, { getACard: increment(1) }) }}
              >
                {lang.getabcxCardToday[language]}
              </button>
            </Link>
          </div>

          <div className="lg:block hidden w-[417px] text-primary" style={{ color: eliteCompanyDoc?.useCustomColors ? (eliteCompanyDoc.primaryColor) : '' }}>
            <svg stroke="currentColor" viewBox="0 0 417 2493" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="225.5" y="364.5" width="219" height="467" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="225.5" y="1536.5" width="219" height="467" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="352.5" y="575.5" width="219" height="433" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="293.5" y="1118.5" width="278" height="467" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="2.5" y="-41.5" width="336" height="99" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="137.5" y="-130.5" width="307" height="370" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="137.5" y="968.5" width="307" height="370" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="183.5" y="2120.5" width="261" height="370" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="293.5" y="175.5" width="489" height="149" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="161.5" y="462.5" width="489" height="149" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="136.5" y="2308.5" width="489" height="149" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="329.5" y="1907.5" width="489" height="149" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="217.5" y="1044.5" width="489" height="149" rx="8.5" fill='none' strokeWidth='5' />
              <rect x="202.5" y="-41.5" width="489" height="149" rx="8.5" fill='none' strokeWidth='5' />
            </svg>

          </div>
        </div>

        <ProfileMenu open={menuToggle}
          onOpen={() => { setMenuToggle(!menuToggle) }}
          language={language}
          user={user}
          navigate={navigate}
          orderNumber={orderNumber}
          setLanguageSelection={setLanguageSelection} />
        <div className="lg:block hidden">
          <Footer translate />
        </div>
      </div>
    </>
  );
};
