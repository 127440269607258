import { Switch, styled } from "@mui/material";
import { DocumentReference, updateDoc } from "firebase/firestore";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EliteCompany, User } from "../types";
import { useOutsideAlerter } from "./SocialDropDown";
interface AdminCardProps
{
  cardnumber?: number;
  open?: boolean;
  userRef?: DocumentReference;
  eliteCompanyRef?: DocumentReference;
  userDoc?: User;
  eliteCompanyDoc?: EliteCompany;
  callback?: Function;
  callbackReassign: Function;
  deactivateUser?: Function;
}

const CustomSwitch = styled(Switch)`
  & .MuiSwitch-track {
    background-color: #ea4335;
    opacity: 1;
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #34a853;
    opacity: 1;
  }

  & .MuiSwitch-thumb {
    background-color: #fff;
  }
`;

export const AdminCard = ({ ...props }: AdminCardProps) =>
{
  const [toggle, setToggle] = useState(props.open);
  const [admin, setAdmin] = useState(props.userDoc?.eliteAdmin);
  const [disableEditing, setDisableEditing] = useState(props.userDoc?.editingDisabled);
  const adminCards = props.eliteCompanyDoc?.adminCards;
  const navigate = useNavigate();
  const callbackReassign = props.callbackReassign;
  const select = useRef<HTMLDivElement>(null);
  useOutsideAlerter(select, () => { setToggle(false) });

  return (
    <>
      <div ref={select}
        className={
          (admin ? "" : "lg:mt-[19px]") +
          " relative flex flex-col h-full lg:basis-1/4 lg:max-w-[323px] flex-grow px-[10px] mb-[29px] justify-start"
        }
      >
        <div
          className={
            (admin ? "flex" : "hidden") +
            "  flex-row justify-center bg-secondary rounded-t-[11px]"
          }
        >
          <div className="font-custom text-white font-black text-[14px]/[19px] tracking-[0.05]">
            Admin
          </div>
        </div>
        <div
          className={
            (admin ? "rounded-t-none" : "rounded-t-[10px]") +
            " lg:block hidden overflow-hidden"
          }
        >
          <img
            src={(props.userDoc?.photo !== '' && props.userDoc?.photo !== 'no' && props.userDoc?.photo) ? props.userDoc?.photo : "https://firebasestorage.googleapis.com/v0/b/business-card-x.appspot.com/o/files%2FNoProfileImage.png?alt=media&token=51cc674a-0ee9-4120-ad90-f3cc3819d854"}
            alt="profilepic"
            className="w-[323px] h-[323px] object-cover"
          />
        </div>
        <div
          className={
            (admin ? "rounded-t-none" : "rounded-t-[10px] lg:rounded-t-none") +
            " lg:mt-[-34px] flex flex-col shadow-custom flex-grow " + (toggle ? '' : 'rounded-b-[10px] shadow-custom overflow-clip')
          }
        >
          <div className="flex flex-row w-full flex-grow ">
            <div className="lg:hidden block w-1/4">
              <img
                className="object-cover w-[323px] h-[323px]"
                src={props.userDoc?.photo !== '' && props.userDoc?.photo !== 'no' ? props.userDoc?.photo : "https://firebasestorage.googleapis.com/v0/b/business-card-x.appspot.com/o/files%2FNoProfileImage.png?alt=media&token=51cc674a-0ee9-4120-ad90-f3cc3819d854"}
                alt="profilepic"
              />
            </div>
            <div
              className="lg:w-3/4 w-2/4 bg-button flex flex-col justify-between lg:pl-[25px] pl-[16px] lg:pt-[17px] lg:pb-[24px] lg:py-0 py-[9px]"
              style={{
                backgroundColor: props.eliteCompanyDoc?.useCustomColors
                  ? props.eliteCompanyDoc?.secondaryColor
                  : "#252529",
              }}
            >
              <div className={((props.eliteCompanyDoc?.secondaryWhite || !props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}>
                <div className={" font-custom font-black lg:text-[15px]/[25px] text-[18px]/[25px] tracking-[0.05em] lg:mb-0 mb-[4px] whitespace-normal overflow-hidden overflow-ellipsis "}>
                  {props.userDoc?.name}
                </div>
                <div className="lg:mr-0  font-custom font-normal lg:text-[14px]/[17px] text-[16px]/[17px] tracking-[0.05em]  lg:mb-0 mb-[8px]">
                  {props.userDoc?.jobTitle}
                </div>
              </div>
              <div className=" flex flex-row gap-[11px] items-center ">
                <div className={"font-custom font-light text-[10px]/[19px] tracking-[0.05em] " + ((props.eliteCompanyDoc?.secondaryWhite || !props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}>
                  Allow Editing
                </div>
                <div>
                  <CustomSwitch
                    size="small"
                    checked={!disableEditing}
                    onChange={async () =>
                    {
                      await updateDoc(props.userRef!, {
                        ...props.userDoc,
                        editingDisabled: !disableEditing,
                      });
                      setDisableEditing(!disableEditing);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/4 flex flex-col ">
              <div className="bg-inset h-1/2 flex-row justify-center items-center">
                <div className="w-full h-full flex flex-row justify-center items-center">
                  <div className="font-custom font-black text-center text-button text-[13px]/[25px] tracking-[0.05em] py-[14px] px-[12px]">
                    Card {props.cardnumber}
                  </div>
                </div>
              </div>
              <div className=" h-1/2 flex-row justify-center items-center">
                <button
                  className={
                    " w-full h-full flex flex-row justify-center items-center " + ((props.eliteCompanyDoc?.whiteArrows && props.eliteCompanyDoc?.useCustomColors && !toggle) ? " text-white " : " text-button ") +
                    (toggle ? " bg-white text-button " : " bg-primary ") +
                    ((!props.eliteCompanyDoc?.useCustomColors && toggle) ? " text-primary " : "") +
                    ((!props.eliteCompanyDoc?.useCustomColors && !toggle) ? " text-white " : "")
                  }
                  style={{
                    backgroundColor: toggle
                      ? "#FFFFFF"
                      : props.eliteCompanyDoc?.useCustomColors
                        ? props.eliteCompanyDoc?.primaryColor
                        : "#fbbc05",
                  }}
                  onClick={() => setToggle(!toggle)}
                >
                  <div className={toggle ? "rotate-0" : "rotate-180 "}>
                    <svg
                      width="33"
                      height="17"
                      viewBox="0 0 33 17"
                      stroke="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31 15L19.0631 3.05777C17.6534 1.64741 15.3466 1.64741 13.9369 3.05777L2 15"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>

        </div>
        <div className="absolute z-50 top-full  inset-0 m-0 px-[10px]">
          <div className="bg-white rounded-b-[10px] shadow-custom overflow-clip">


            <div
              className={
                (toggle ? "flex " : "hidden") + " flex-col gap-[16px] p-[16px]"
              }
            >
              <Link className={" font-custom font-medium shadow-custom text-[15px]/[25px] text-center bg-primary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] " + ((props.eliteCompanyDoc?.primaryWhite && props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")} style={{
                backgroundColor: props.eliteCompanyDoc?.useCustomColors
                  ? props.eliteCompanyDoc?.primaryColor
                  : "#fbbc05",
              }} to={"/profile/" + props.userDoc?.orderNumber} target="_blank" rel="noopener noreferrer">

                View Profile


              </Link>


              <a
                className={" cursor-pointer font-custom font-medium text-black shadow-custom text-[15px]/[25px] text-center bg-primary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] " + ((props.eliteCompanyDoc?.primaryWhite && props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                target="_blank" rel="noopener noreferrer"
                style={{
                  backgroundColor: props.eliteCompanyDoc?.useCustomColors
                    ? props.eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
                onClick={() =>
                {
                  navigate("/admin-edit-profile/", {

                    state: { orderNumber: props.userDoc?.orderNumber },
                  });
                }}
              >
                Edit Profile
              </a>
              <button
                className={" font-custom font-medium text-black shadow-custom text-[15px]/[25px] text-center bg-primary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] " + ((props.eliteCompanyDoc?.primaryWhite && props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: props.eliteCompanyDoc?.useCustomColors
                    ? props.eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
                onClick={() =>
                {
                  callbackReassign();
                }}
              >
                Reassign
              </button>
              <button
                className=" font-custom font-medium text-white shadow-custom text-[15px]/[25px] text-center bg-red-500 rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px]"
                onClick={async () =>
                {
                  await props.deactivateUser!();
                }}
              >
                Deactivate
              </button>
              <button
                className={" font-custom font-medium text-white shadow-custom text-[15px]/[25px] text-center bg-secondary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] "}
                onClick={async () =>
                {
                  await props.callback!(
                    admin,
                    props.userRef,
                    props.userDoc?.orderNumber,
                    adminCards,
                    props.userDoc,
                    props.eliteCompanyRef,
                    props.eliteCompanyDoc,
                    setAdmin
                  );
                }}
              >
                {admin ? "Remove Admin" : "Make Admin"}
              </button>
              <a
                target="_blank" rel="noopener noreferrer"
                onClick={() =>
                {
                  navigate("/replacement-elitecard", {
                    state: { orderNumber: props.userDoc?.orderNumber },
                  });
                }}
                className={"cursor-pointer font-custom font-medium shadow-custom text-[15px]/[25px] text-center bg-button rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] opacity-100 " + ((props.eliteCompanyDoc?.secondaryWhite || !props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: props.eliteCompanyDoc?.useCustomColors
                    ? props.eliteCompanyDoc?.secondaryColor
                    : "#252529",
                }}
              >
                Order Replacement Card
              </a>
            </div>
          </div>
        </div>
      </div >


    </>
  );
};
